import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const checkLoginStatus = () => {
    const token = localStorage.getItem('access_token');
    setIsLoggedIn(!!token);
  };

  const checkUrlForToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    const status = urlParams.get('status');

    if (accessToken && status === '200') {
      localStorage.setItem('access_token', accessToken);
      setIsLoggedIn(true);
      navigate('/'); 
      window.history.pushState({}, document.title, window.location.pathname);
    }
  };

  useEffect(() => {
    checkLoginStatus();
    checkUrlForToken();
  }, []);

  const logout = () => {
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
    navigate('/'); 
  };

  const value = {
    isLoggedIn,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};