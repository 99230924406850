import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

import { isAuthenticated } from '../views/authentication/auth/AuthLogin';


/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const OurDataOverview = Loadable(lazy(() => import('../views/owler/OurDataOverview')));
const OurDatasets = Loadable(lazy(() => import('../views/owler/OurDatasets')));
const LiveCrawling = Loadable(lazy(() => import('../views/owler/LiveCrawling')));
const OwlerDashboard = Loadable(lazy(() => import('../views/admin/OwlerDashboard')));
const StorageStatus = Loadable(lazy(() => import('../views/admin/StorageStatus')));
const FrontierStatus = Loadable(lazy(() => import('../views/admin/FrontierStatus')));
const OwlerStatus = Loadable(lazy(() => import('../views/admin/OwlerStatus')));
const NewCrawl = Loadable(lazy(() => import('../views/owler-on-demand/NewCrawl')));
const MyCrawls = Loadable(lazy(() => import('../views/owler-on-demand/MyCrawls')));
const TermsOfUse = Loadable(lazy(() => import('../views/owler-on-demand/TermsOfUse')));
const RobotsTxtTDMRep = Loadable(lazy(() => import('../views/webmaster/RobotsTxtTDMRep')));
const TakedownRequest = Loadable(lazy(() => import('../views/webmaster/TakedownRequest')));
const MyWebsite = Loadable(lazy(() => import('../views/webmaster/MyWebsite')));
const AboutUs = Loadable(lazy(() => import('../views/owler/AboutUs')));
const Impressum = Loadable(lazy(() => import('../views/owler/Impressum')));
const PrivacyStatement = Loadable(lazy(() => import('../views/owler/PrivacyStatement')));

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Callback = Loadable(lazy(() => import('../views/authentication/Callback')));

const CollectionIndexPublic = Loadable(lazy(() => import('../views/collectionindex/CollectionIndexPublic')))
const MyOwler = Loadable(lazy(() => import('../views/myowler/MyOwler')))
const CollectionIndexPrivat = Loadable(lazy(() => import('../views/myowler/CollectionIndexPrivat')))
const DataOffloadConfiguration = Loadable(lazy(() => import('../views/myowler/DataOffloadConfiguration')))
const MyComputeTasks = Loadable(lazy(() => import('../views/myowler/MyComputeTasks')))
const MyProfile = Loadable(lazy(() => import('../views/profile/MyProfile')))
const DomainInfoPage = Loadable(lazy(() => import('../views/webmaster/DomainInfoPage')));

const OWIGeneralSearch = Loadable(lazy(() => import('../views/ours/OWIGeneralSearch')))
const MainIndexPublic = Loadable(lazy(() => import("../views/collectionindex/MainIndexPublic")))
const OWIUrlAndTitleSearch= Loadable(lazy(() => import("../views/ours/OWIUrlAndTitleSearch")))

const AdminRoutes = () => (
  <Routes>
    <Route path='admin/active-crawls' element={<OwlerDashboard />} />
    <Route path='admin/storage' element={<StorageStatus />} />
    <Route path='admin/url-frontier' element={<FrontierStatus />} />
    <Route path='admin/owlers' element={<OwlerStatus />} />
  </Routes>
);

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/owler" replace /> },
      { path: 'owler', exact: true, element: <AboutUs /> },
      { path: 'owler/our_data', exact: true, element: <OurDataOverview /> },
      { path: 'owler/our_datasets', exact: true, element: <OurDatasets/> },
      { path: 'owler/mainowi', exact: true, element: <MainIndexPublic /> },
      { path: 'owler/live', exact: true, element: <LiveCrawling /> },
      { path: 'owler/ours', exact: true, element: <OWIUrlAndTitleSearch /> },
      { path: 'owler/ours/:indexname', exact: true, element: <OWIGeneralSearch /> },
      { path: 'myowler/index-now', exact: true, element: <NewCrawl /> },
      { path: 'myowler/my-crawls', exact: true, element: <MyCrawls /> },
      { path: 'terms-of-use', exact: true, element: <TermsOfUse /> },
      { path: 'privacy-statement', exact: true, element: <PrivacyStatement /> },
      { path: 'impressum', exact: true, element: <Impressum /> },
      { path: 'myowler/robots', exact: true, element: <RobotsTxtTDMRep /> },
      { path: 'myowler/takedown', exact: true, element: <TakedownRequest /> },
      { path: 'myowler/mywebsite', exact: true, element: <MyWebsite /> },
      { path: 'myowler/details/:domain', exact: true, element: <DomainInfoPage /> },
      { path: '/myowler/:username/', exact: true, element: <MyOwler /> },
      { path: 'owler/my-profile', exact: true, element: <MyProfile /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
      { 
        path: 'admin/*', 
        element: isAuthenticated() ? <AdminRoutes /> : <Navigate to="/auth/login" /> 
      },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/callback', element: <Callback /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];



export default Router;