import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import Router from './routes/Router';
import { AuthProvider } from './contexts/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

import { baselightTheme } from "./theme/DefaultColors";
import aboutUsImage from 'src/assets/images/backgrounds/about-us.png';

function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;
  const currentDomain = window.location.origin;


  return (
    <HelmetProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Helmet>
            <title>The Open Web Index Dasbhoard</title>
            <meta name="description" content="Explore the Open Web Index Dashboard, your gateway to accessing and analyzing vast web data. Learn about our mission to provide a fair, open, and diverse web index." />
            <meta name="keywords" content="Open Web Index, web data, web index, data analysis, web search, OWI, open data, web crawling, data access" />
            <meta name="author" content="Open Web Index Team" />
            <meta property="og:title" content="The Open Web Index Dasbhoard" />
            <meta property="og:description" content="Discover the Open Web Index Dashboard, designed to offer a comprehensive view of web data and services. Join us in our mission for an open and free web." />
            <meta property="og:image" content={aboutUsImage} />
            <meta property="og:url" content={`${currentDomain}/owler`} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="The Open Web Index Dasbhoard" />
            <meta name="twitter:description" content="Join the Open Web Index initiative to explore and utilize web data for a fair and open internet." />
            <meta name="twitter:image" content={aboutUsImage} />
            <link rel="canonical" href={`${currentDomain}/owler`} />
          </Helmet>
          {routing}
        </ThemeProvider>
      </AuthProvider>
    </HelmetProvider>

  );
}

export default App;
