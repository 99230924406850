
const tables = {
    global: {
        'table': {
            border: '2px solid black',
            borderCollapse: 'collapse',
        },
        'th, td': {
            border: '2px solid black',
        },
    }
  };
  
  export default tables;
  